// @ts-ignore
import ApiService from "@/common/api.service"
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_LABEL = "listLabel"
export const DELETE_LABEL = "deleteLabel"
// mutation types
export const SET_LIST_LABEL = "getListLabel"

export const state = () => ({
    labels: [] as any
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    labels: state => state.labels
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_LABEL](state, data) {
        state.labels = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_LABEL]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.LABEL, payload).then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_LABEL, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    },
    [DELETE_LABEL]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.LABEL, payload.id).then((data: any) => {
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                })
        })
    }
}
const labelModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default labelModule
