// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_CATEGORY_BY_PAGE = "listCategoryPage"
export const LIST_CATEGORY_SEARCH = "listCategorySearch"
export const GET_LIST_CATEGORY = "listCategory"
export const DETAIL_CATEGORY = "dtailCategory"
export const DELETE_CATEGORY = "deleteCategory"
export const CREATE_CATEGORY = "createCategory"
export const UPDATE_CATEGORY = "updateCategory"
export const REMOVE_PARENT = "remove-parentId"
export const LIST_EXAM_BY_CATEGORY = "list-exam-category"

// mutation types
export const SET_CATEGORY = "category"
export const SET_LIST_CATEGORY = "getListCategory"
export const SET_ERROR = "setError"

export const state = () => ({
    category: {},
    categories: [] as any,
    list_category_search: [] as any,
    loadedAllCategories: false
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    category: state => state.category,
    categories: state => state.categories,
    listCategorySearch: state => state.list_category_search,
    loadedAllCategories: state => state.loadedAllCategories
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_CATEGORY](state, data) {
        state.categories = data;
    },
    [LIST_CATEGORY_BY_PAGE](state, data) {
        state.list_category_search = data;
    },
    [SET_CATEGORY](state, data) {
        state.category = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_CATEGORY]({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.API_CATEGORY, '').then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_CATEGORY, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [LIST_CATEGORY_BY_PAGE]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.API_CATEGORY}/search`, credentials).then((data: any) => {
                if (data.data.status) {
                    commit(LIST_CATEGORY_BY_PAGE, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [LIST_CATEGORY_SEARCH]({ state }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.API_CATEGORY}/search`, credentials).then((data: any) => {
                if (data && data.data.status) {
                    const page = credentials.page
                    const limit = credentials.limit
                    const newCategories = data.data.data.data
                    if (page == 1) {
                        state.categories = newCategories
                    } else {
                        state.categories.push(...newCategories)
                    }
                    if (!newCategories || newCategories.length == 0 || newCategories.length < limit) {
                        state.loadedAllCategories = true
                    }
                } else {
                    state.loadedAllCategories = true
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [LIST_EXAM_BY_CATEGORY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.LIST_EXAM_BY_CATEGORY, credentials)
                .then((data: any) => {
                    if (data) {
                        resolve(data.data); 
                    } 
                })
                .catch(({ response }) => {
                    reject(response);
                });
                
        });
    },
    [DETAIL_CATEGORY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.API_CATEGORY, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_CATEGORY, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_CATEGORY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.API_CATEGORY, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_CATEGORY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.API_CATEGORY}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [REMOVE_PARENT](context, item) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${REMOVE_PARENT}`, { id: item.id })
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [DELETE_CATEGORY](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.API_CATEGORY, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const categoryModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default categoryModule