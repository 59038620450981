// @ts-ignore
import ApiService from "@/common/api.service"
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_SEARCH_QUERY = "listSearchQuery"
export const DELETE_SEARCH_QUERY = "deleteSearchQuery"
// mutation types
export const SET_LIST_SEARCH_QUERY = "getListSearchQuery"

export const state = () => ({
    searchQueries: [] as any
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    searchQueries: state => state.searchQueries
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_SEARCH_QUERY](state, data) {
        state.searchQueries = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_SEARCH_QUERY]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.SEARCH_QUERY, payload).then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_SEARCH_QUERY, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    },
    [DELETE_SEARCH_QUERY]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.SEARCH_QUERY, payload.id).then((data: any) => {
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response.data);
                })
        })
    }
}
const searchQueryModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default searchQueryModule
