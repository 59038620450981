import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApiService from './common/api.service'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/tailwind.css'
import './assets/main.scss'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import Toaster from '@meforma/vue-toaster'
import './plugins/validate'
import i18n from './common/i18n'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue'
import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far, fab)
dom.watch()

import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'

ApiService.init();
const app = createApp(App)
app.use(CkeditorPlugin)
app.use(store)
app.use(router)
app.use(Toaster)
app.use(i18n)

app.use(VueAxios, axios)
app.use(ElementPlus)
app.use(VueTimepicker)

app.mount('#app')
app.component("font-awesome-icon", FontAwesomeIcon)
app.component('EasyDataTable', Vue3EasyDataTable);
