
import ApiService from "@/common/api.service"

import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_ROADS_SEARCH = "/roads"
export const ADD_ROAD = "addRoad"
export const DETAIL_ROAD = "detailRoad"
export const UPDATE_ROAD = "updateRoad"
export const DELETE_ROAD = "deleteRoad"

// mutation types

export const SET_LIST_ROADS = "setListRoads"
export const SET_DETAIL_ROAD = "setDetailRoads"
export const SET_ERROR = "setError"


export const state = () => ({
    errors: null,
    list_roads: [],
    detail_road: {},
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    listRoadsSearch: state => state.list_roads,
    detailRoad: state => state.detail_road,
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_ROADS](state, error) {
        state.list_roads = error;
    },
    [SET_DETAIL_ROAD](state, error) {
        state.detail_road = error;
    },
}

const actions: ActionTree<RootState, RootState> = {
    [DETAIL_ROAD](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.ROADS, id)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_DETAIL_ROAD, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    },
    [LIST_ROADS_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(LIST_ROADS_SEARCH, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_LIST_ROADS, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    },

    [ADD_ROAD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.ROADS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                })
        })
    },
    [UPDATE_ROAD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.ROADS}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                })
        })
    },
    [DELETE_ROAD](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.ROADS}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                })
        })
    }
}
const roadModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default roadModule