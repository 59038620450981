import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import JwtService from "@/common/jwt.service";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/home",
        name: '',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: "/home",
                component: () => import("@/views/home.vue")
            },
            {
                path: "/category/list",
                component: () => import("@/views/exam/category/list_category.vue"),
                meta: { key: 'category' },
            },
            {
                path: "/category/update/:id",
                component: () => import("@/views/exam/category/update_category.vue"),
                meta: { key: 'category' },
            },
            {
                path: "/category/add",
                component: () => import("@/views/exam/category/add_category.vue"),
                meta: { key: 'category' },
            },
            {
                path: "/exam/list_exam",
                component: () => import("@/views/exam/exams/list_exam.vue"),
                meta: { key: 'exam' },
            },
            {
                path: "/exam/list_exam_ecommerce",
                component: () => import("@/views/exam/exams/list_exam_ecommerce.vue"),
                meta: { key: 'exam_ecommerce' },
            },
            {
                path: "/exam/add_exam",
                component: () => import("@/views/exam/exams/add_exam.vue"),
                meta: { key: 'exam' },
            },
            {
                path: "/test",
                component: () => import("@/views/test.vue")
            },
            {
                path: "/exam/edit/:id",
                component: () => import("@/views/exam/exams/edit.vue"),
                meta: { key: 'exam' },
            },
            {
                path: "/list_admins",
                component: () => import("@/views/admins/list_admins.vue"),
                meta: { key: 'admin' },
            },
            {
                path: "/add_admin",
                component: () => import("@/views/admins/add_admin.vue"),
                meta: { key: 'admin' },
            },
            {
                path: "/detail_admin/:id",
                component: () => import("@/views/admins/detail_admin.vue"),
                meta: { key: 'admin' },
            },
            {
                path: "/list_users",
                component: () => import("@/views/users/list_users.vue"),
                meta: { key: 'users' },
            },
            {
                path: "/add_user",
                component: () => import("@/views/users/add_user.vue"),
                meta: { key: 'users' },
            },
            {
                path: "/detail_user/:id",
                component: () => import("@/views/users/detail_user.vue"),
                meta: { key: 'users' },
            },
            {
                path: "/take_exam_user/:id",
                component: () => import("@/views/users/list_take_exam.vue"),
                meta: { key: 'users' },
            },
            {
                path: "/list_comments",
                component: () => import("@/views/comment/list_comments.vue"),
                meta: { key: 'comments' },
            },
            {
                path: "/category/update-index-exam/:id",
                component: () => import("@/views/exam/category/update_index_exam.vue"),
                meta: { key: 'category' },
            }
        ]
    },
    {
        path: '/',
        name: 'exam_user',
        component: () => import('@/components/layouts/Dashboard.vue'),
        children: [
            {
                path: "/exam-user/list_exam",
                component: () => import("@/views/exam_user/list_exam.vue"),
                meta: { key: 'exam_user' },
            },
            {
                path: "/exam-user/list_exam_ecommerce",
                component: () => import("@/views/exam_user/list_exam_ecommerce.vue"),
                meta: { key: 'exam_user_ecommerce' },
            },
            {
                path: "/exam-user/add_exam",
                component: () => import("@/views/exam_user/add_exam.vue"),
                meta: { key: 'exam_user' },
            },
            {
                path: "/exam-user/edit/:id",
                component: () => import("@/views/exam_user/edit.vue"),
                meta: { key: 'exam_user' },
            }
        ]
    },
    {
        path: '/',
        name: 'statistics',
        component: () => import('@/components/layouts/Dashboard.vue'),
        children: [
            {
                path: "/statistics/list",
                component: () => import("@/views/statistics/list.vue"),
                meta: { key: 'statistics_list' },
            },
            {
                path: "/statistics/user",
                component: () => import("@/views/statistics/user/list_user.vue"),
                meta: { key: 'statistics_user' },
            },
            {
                path: "/statistics/user/:id/:date",
                component: () => import("@/views/statistics/user/list_take_exam.vue"),
                meta: { key: 'statistics_user_detail' },
            },
            {
                path: "/statistics/exam",
                component: () => import("@/views/statistics/exam/list_exam.vue"),
                meta: { key: 'statistics_exam' },
            },
            {
                path: "/statistics/question",
                component: () => import("@/views/statistics/question/list_question.vue"),
                meta: { key: 'statistics_question' },
            }
        ]
    },
    {
        path: '/',
        name: 'roads',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: "/roads/list",
                component: () => import("@/views/road/list.vue"),
                meta: { key: 'roads_list' },
            },
            {
                path: "/roads/add",
                component: () => import("@/views/road/create.vue"),
                meta: { key: 'roads_list' },
            },
            {
                path: "/roads/update/:id",
                component: () => import("@/views/road/create.vue"),
                meta: { key: 'roads_list' },
            },
            {
                path: "/road-sent/list",
                component: () => import("@/views/road/list-sent.vue"),
                meta: { key: 'roads_sent_list' }
            },
        ]
    },
    {
        path: '/',
        name: 'keywords',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: "/keywords/search_query",
                component: () => import("@/views/keyword/list_search_query.vue"),
                meta: { key: 'search_query_list' },
            },
            {
                path: "/keywords/keyword",
                component: () => import("@/views/keyword/list_keyword.vue"),
                meta: { key: 'keyword_list' },
            }
        ]
    },
    {
        path: '/',
        name: 'companies',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: "/companies/list",
                component: () => import("@/views/companies/list_company.vue"),
                meta: { key: 'company' },
            },
            {
                path: "/companies/add",
                component: () => import("@/views/companies/add_company.vue"),
                meta: { key: 'company' },
            },
            {
                path: "/companies/update/:id",
                component: () => import("@/views/companies/update_company.vue"),
                meta: { key: 'company' },
            },
        ]
    },
    {
        path: '/',
        name: 'personal_access_keys',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: '/personal_access_keys/list',
                name: 'list_personal_access_key',
                component: () => import('@/views/personal_access_keys/list_personal_access_key.vue'),
                meta: { key: 'personal_access_keys' },
            }
        ]
    },
    {
        path: '/',
        name: 'roles',
        component: () => import("@/components/layouts/Dashboard.vue"),
        children: [
            {
                path: '/roles/list',
                name: 'list_role',
                component: () => import('@/views/roles/list_role.vue'),
                meta: { key: 'roles' },
            }
        ]
    },
    {
        path: '/',
        name: 'auth',
        component: () => import("@/views/auth/Auth.vue"),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/auth/login.vue')
            },
            {
                path: '/forgot-password',
                name: 'forgot-password',
                component: () => import('@/views/auth/forgot_password.vue')
            },
            {
                path: '/update-password',
                name: 'update-password',
                component: () => import('@/views/auth/update_password.vue')
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach(async (to, from, next) => {
    const array: Array<string> = ['login', 'forgot-password', 'update-password']
    if (JwtService.getToken()) {
        if (to.name == 'login') {
            next('/')
        }
        else {
            next()
        }
    }
    else {
        if ((typeof to.name == 'string' && !array.includes(to.name)) || to.name == undefined) {
            next('/login')
        } else {
            next()
        }
    }
})
// @ts-ignore
export default router


