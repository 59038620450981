// @ts-ignore
import ApiService from "@/common/api.service"
// @ts-ignore
import {GetterTree, ActionTree, MutationTree,Module} from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_QUESTION_TAKE_EXAM_STATISTICS = "questionTakeExamStatistics"
// mutation types
export const SET_LIST_QUESTION_TAKE_EXAM_STATISTICS = "getListTakeQuestion"

export const state = () => ({
    question_take_exams_statistics: [] as any
})

 type RootState = ReturnType<typeof state>
 const getters: GetterTree<RootState, RootState> = {
     question_take_exams_statistics :state  =>  state.question_take_exams_statistics
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_QUESTION_TAKE_EXAM_STATISTICS](state, data) {
        state.question_take_exams_statistics = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_QUESTION_TAKE_EXAM_STATISTICS]( {commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_QUESTION_TAKE_EXAM_STATISTICS, payload).then((data:any)=>{
                if (data.data.status) {
                    commit(SET_LIST_QUESTION_TAKE_EXAM_STATISTICS, data.data.data)
                }
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    }
}
const questionTakeExamStatisticsModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default questionTakeExamStatisticsModule
