// @ts-ignore
import ApiService from "@/common/api.service"
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_ROAD_SENT_SEARCH = "listRoadSentsSearch"
// mutation types
export const SET_LIST_ROAD_SENT = "getListRoadSent"

export const state = () => ({
    roadSents: [] as any
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    roadSents: state => state.roadSents
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_ROAD_SENT](state, data) {
        state.roadSents = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_ROAD_SENT_SEARCH]({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_ROAD_SENT_SEARCH, payload).then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_ROAD_SENT, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                })
        })
    }
}
const roadSentModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default roadSentModule
