import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

/* eslint-disable @typescript-eslint/no-explicit-any */

export const state = () => ({
    isLoading: null
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    isLoading: state => {
        return state.isLoading
    }
}

const mutations: MutationTree<RootState> = {
    setLoading: (state, payload) => {
        state.isLoading = payload
    }
}

const actions: ActionTree<RootState, RootState> = {}
const loadingModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default loadingModule