import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

import { onMounted } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  const debounce = (callback: (...args: any[]) => void, delay: number) => {
    let tid: any;
    return function (...args: any[]) {
      const ctx = self;
      tid && clearTimeout(tid);
      tid = setTimeout(() => {
        callback.apply(ctx, args);
      }, delay);
    };
  };

  const _ = (window as any).ResizeObserver;
  (window as any).ResizeObserver = class ResizeObserver extends _ {
    constructor(callback: (...args: any[]) => void) {
      callback = debounce(callback, 20);
      super(callback);
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view)
  ]))
}
}

})